<template>
  <div class="doc-container font-roboto-light top-border">
    <div class="container">
      <div class="row flex-xl-nowrap g-0">
        <SidebarDocumentation/>
        <!-- ****************************** DOC MAIN BODY ********************************* -->
        <main class="col-xl-9 col-md-8 doc-main-body">

          <h2>Google Sign-In</h2>
          <p class="mt-15">Here are the steps to enable Google Sign-In for your website.</p>

          <h3>1. Create a Google OAuth 2.0 Client ID</h3>
          <ul class="list-item-one">
            <li>
                Go to the  <a href="https://console.cloud.google.com" target="_blank" rel="noopener noreferrer" class="underline-link">Google Cloud Console</a> and create a new project.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/socialproviders/gcc_new_project.png"
                    alt=""
                    class="shapes screen-one"
                    style="width: 85%; height: 85%"
                />
              </div>
              <div class="documentation-image ">
                <img
                    src="../../../../assets/images/documentation/socialproviders/gcc_new_project2.png"
                    alt=""
                    class="shapes screen-one"
                    style="width: 85%; height: 85%"
                />
              </div>
              </li>
            <li class="mt-15">
              Search for <span class="font-roboto-medium">Oauth consent screen</span>, select <span class="font-roboto-medium">External</span>, and click <span class="font-roboto-medium">'Create'</span>.
              <div class="documentation-image ">
                <img
                    src="../../../../assets/images/documentation/socialproviders/gcc_external.png"
                    alt=""
                    class="shapes screen-one"
                    style="width: 85%; height: 85%"
                />
              </div>
            </li>
            <li class="mt-15">
              Fill in the forms and click <span class="font-roboto-medium">'Save and continue'</span>. You can skip the App Domain section if you don't have a domain yet.
              <div class="documentation-image ">
                <img
                    src="../../../../assets/images/documentation/socialproviders/gcc_forms.png"
                    alt=""
                    class="shapes screen-one"
                    style="width: 85%; height: 85%"
                />
              </div>
              <div class="documentation-image ">
                <img
                    src="../../../../assets/images/documentation/socialproviders/gcc_forms2.png"
                    alt=""
                    class="shapes screen-one"
                    style="width: 85%; height: 85%"
                />
              </div>
            </li>
            <li class="mt-25">
              Leave the scopes as they are, click <span class="font-roboto-medium">'Save and continue'</span>. Add test users (or not, it is optional), and click <span class="font-roboto-medium">'Save and continue'</span>.
              <div class="documentation-image ">
                <img
                    src="../../../../assets/images/documentation/socialproviders/gcc_scopes.png"
                    alt=""
                    class="shapes screen-one"
                    style="width: 85%; height: 85%"
                />
              </div>
            </li>
            <li class="mt-25">
              On summary page, click <span class="font-roboto-medium">'Back to Dashboard'</span>.
              <div class="documentation-image ">
                <img
                    src="../../../../assets/images/documentation/socialproviders/gcc_summary.png"
                    alt=""
                    class="shapes screen-one"
                    style="width: 85%; height: 85%"
                />
              </div>
            </li>
            <li class="mt-25">
              Then publish the app so that anyone with a Google account can sign in to your app.
              <div class="documentation-image ">
                <img
                    src="../../../../assets/images/documentation/socialproviders/gcc_publish.png"
                    alt=""
                    class="shapes screen-one"
                    style="width: 85%; height: 85%"
                />
              </div>
              <div class="documentation-image ">
                <img
                    src="../../../../assets/images/documentation/socialproviders/gcc_publish2.png"
                    alt=""
                    class="shapes screen-one"
                    style="width: 85%; height: 85%"
                />
              </div>
            </li>
            <li class="mt-25">
              Click on the credentials tab and create new credentials. Select <span class="font-roboto-medium">OAuth client ID</span>. As type, choose <span class="font-roboto-medium">Web application</span>.
            <div class="documentation-image ">
              <img
                  src="../../../../assets/images/documentation/socialproviders/gcc_creds1.png"
                  alt=""
                  class="shapes screen-one"
                  style="width: 85%; height: 85%"
              />
            </div>
            <div class="documentation-image ">
              <img
                  src="../../../../assets/images/documentation/socialproviders/gcc_creds2.png"
                  alt=""
                  class="shapes screen-one"
                  style="width: 85%; height: 85%"
              />
            </div>
            </li>
              <li class="mt-25">
                Fill in <span class="font-roboto-medium">Authorised JavaScript origins</span> for both <span class="font-roboto-medium">dev</span>  and <span class="font-roboto-medium">prod</span> accounts. You can get it by copying the Cognito domains from your AWS dev and prod accounts (go to Cognito, select your user pool, go to App Integration and copy the domain).
                <div class="documentation-image ">
                  <img
                      src="../../../../assets/images/documentation/socialproviders/gcc_cognito_domain.png"
                      alt=""
                      class="shapes screen-one"
                      style="width: 85%; height: 85%"
                  />
                </div>
                <div class="documentation-image ">
                  <img
                      src="../../../../assets/images/documentation/socialproviders/gcc_cognito_domain2.png"
                      alt=""
                      class="shapes screen-one"
                  />
                </div>
                <div class="documentation-image ">
                  <img
                      src="../../../../assets/images/documentation/socialproviders/gcc_js_origins.png"
                      alt=""
                      class="shapes screen-one"
                  />
                </div>
              </li>
            <li class="mt-25">
              Fill in <span class="font-roboto-medium">Authorised redirect URIs</span> for both <span class="font-roboto-medium">dev</span> and <span class="font-roboto-medium">prod</span> accounts. Add your Cognito domains there, but also add <span class="font-roboto-medium">/oauth2/idpresponse</span> to each url. Then click <span class="font-roboto-medium">'Click'</span>.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/socialproviders/gcc_redirects.png"
                    alt=""
                    class="shapes screen-one"
                    style="width: 85%; height: 85%"
                />
              </div>
              </li>
            <li class="mt-15">
              You will see a popup with the <span class="font-roboto-medium">ClientID</span> and <span class="font-roboto-medium">Client secret</span>. They are also available in your OAuth 2.0 Client IDs credentials tab. Copy them both.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/socialproviders/gcc_secret.png"
                    alt=""
                    class="shapes screen-one"
                    style="width: 75%; height: 75%"
                />
              </div>
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/socialproviders/gcc_secret2.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
            </li>

            <li class="mt-15">
            Go to the <span class="font-roboto-medium">AWS Secret Manager</span> and add Google ClientID (google_identity_provider_client_id) and Client secret (google_identity_provider_client_secret) there (do it for both <span class="font-roboto-medium">dev</span>  and <span class="font-roboto-medium">prod</span> accounts). Both ClientID and Client secret are the same for dev and prod accounts.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/socialproviders/gcc_secret_manager1.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/socialproviders/gcc_secret_manager2.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/socialproviders/gcc_secret_manager3.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/socialproviders/gcc_secret_manager4.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
            </li>
            </ul>

          <h3 style="margin-top: 35px !important;">2. Set infrastructure to support Google Sign-in and deploy it</h3>
          <ul class="list-item-one">
            <li>
              Go to the <span class="font-roboto-medium">global_config.py</span> and set google_sign_in_supported to True in both <span class="font-roboto-medium">create_global_config_dev()</span> and <span class="font-roboto-medium">create_global_config_prod()</span>:
            </li>
            <CodeContainer :code="globalConfig.code" :language="globalConfig.language"/>
            <li class="mt-15">
              Now just push the code to your GitHub repository:
            </li>
            <CodeContainer class="mt-15" :code="commitPushFinal.code" :language="commitPushFinal.language"/>

          </ul>
          <p>Congratulations! Now users can use Google Sign-in on your website.</p>
          <InfoContainer class="mt-15">
            <span class="font-roboto-medium">INFO 1</span>
            Note: It may take five minutes to a few hours for settings to take effect.
          </InfoContainer>

          <InfoContainer class="mt-15">
            <span class="font-roboto-medium">INFO 2</span>
            Currently you can use a test user email you've provided to the Secret Manager.
            The reason is that AWS does not give you full access to AWS email service (AWS SES) from the start. <br>
            You will have to request AWS (prod account only) to give you production access to AWS SES. <router-link to="/documentation/request-ses" class="underline-link">We will do it later</router-link>. <br>
            If you want to use additional emails now, just add a new verified identity (email) in AWS SES (AWS SES -> Identities -> Create identity).
          </InfoContainer>

          <DocumentationPagination/>
        </main> <!-- /.doc-main-body -->
      </div>
    </div>
  </div>
</template>

<script>
import SidebarDocumentation from "@/components/service-documentation/commons/SidebarDocumentation.vue";
import DocumentationPagination from "@/components/service-documentation/commons/DocumentationPagination.vue";
import {ref} from "vue";
import CodeContainer from "@/components/service-documentation/commons/CodeContainer.vue";
import InfoContainer from "@/components/service-documentation/commons/InfoContainer.vue";

export default {
  name: 'AddGoogle',
  components: {
    InfoContainer,
    CodeContainer,
    SidebarDocumentation,
    DocumentationPagination,
  },
  setup() {
    const globalConfig = ref({
      language: 'python',
      code:
          '# set it to True\n' +
          'google_sign_in_supported=True,'
    });
    const commitPushFinal = ref({
      language: 'bash',
      code:
          'cd saasconstruct # navigate to the project folder\n' +
          'git add .\n'+
          'git commit -m "support Google signin"\n'+
          'git push origin main # pushes code to the main branch (prod)\n'+
          'git push origin main:development # pushes current main branch to development (dev)\n'
    });
    return {
      globalConfig,
      commitPushFinal
    };
  }
}
</script>
