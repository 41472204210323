<template>
  <div class="terms_and_policies">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <!-- Tab panes -->
          <div class="tab-content">
            <div v-for="(item,index) in privacy_data" :key="index"
                 :class="`tab-pane fade ${item.active ? 'active show' : ''}`">
              <h2 class="font-roboto">{{ item.title_intro }}</h2>
              <h3>{{ item.title_main_intro }}</h3>
              <p v-html="item.text_intro_1"></p>
              <h3>{{ item.title_as_visitor }}</h3>
              <p v-html="item.text_as_visitor_1"></p>
              <p v-html="item.text_as_visitor_2"></p>
              <p v-html="item.text_as_visitor_3"></p>
              <h3>{{ item.title_as_customer }}</h3>
              <p v-html="item.text_as_customer_1"></p>
              <p v-html="item.text_as_customer_2"></p>
              <p v-html="item.text_as_customer_3"></p>
              <p v-html="item.text_as_customer_4"></p>
              <p v-html="item.text_as_customer_5"></p>
              <p v-html="item.text_as_customer_6"></p>
              <p v-html="item.text_as_customer_7"></p>
              <p v-html="item.text_as_customer_8"></p>
              <h3>{{ item.title_retention }}</h3>
              <p v-html="item.text_retention_1"></p>
              <p v-html="item.text_retention_2"></p>
              <p v-html="item.text_retention_3"></p>
              <p>
                <em>{{ date_text }}</em>
              </p>
            </div> <!-- /.tab-content -->

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SaasInfraPrivacyPolicy',
  data() {
    return {
      privacy_data: [
        {
          active: true,
          title_intro: 'Privacy Policy',
          title_main_intro: '1. Introduction',
          text_intro_1: 'In this policy, we lay out what data we collect and why, how your data is handled and your rights to your data.',
          title_as_visitor: "2. As a visitor to the saasconstruct.com website",
          text_as_visitor_1: "This website tracks statistics anonymously with <a href=\"https://plausible.io/privacy-focused-web-analytics\">Plausible</a>. It does not set tracking cookies, and it does not collect personal data. Statistics are are not shared with nor sold to other companies.",
          text_as_visitor_2: "This website sometimes saves data in your browser’s localStorage (e.g. when choosing a language). This information is not shared with anyone, not even us.",
          text_as_visitor_3: "This website also collects server error logs for debugging purposes. The logs are anonymised and do not contain personal data.",
          title_as_customer: "3. As a customer and subscriber of saasconstruct.com website",
          text_as_customer_1: "What we collect and what we use it for:",
          text_as_customer_2: "<b>An email address is required to create an account.</b> You need to provide us with your email address if you want to create an account. That’s just so you can log in and personalize your new account, and we can send you invoices, updates or other essential information.",
          text_as_customer_3: "<b>A persistent cookie, as well as temporary JWT (JSON Web Token) and your email token is stored to remember you’re logged in.</b> If you log in to your account, you give us permission to use cookies in cookies storage of the browser, as well as JWT token and email in localStorage, so you don’t have to log in on each returning session. This makes it easier for you to use our product. A cookie and JWT token is a piece of text stored by your browser. Data that are already stored may be deleted at any time.",
          text_as_customer_4: "<b>When you write to us with a question or to ask for help.</b> We keep that correspondence, which includes the email address, so that we have a history of past correspondences to reference if you reach out in the future. We use this data solely in connection with answering the queries we receive.",
          text_as_customer_5: "<b>When you sign up make a purchase, the email is stored in the database,</b> using Amazon Web Services, in eu-central-1 region (Frankfurt).",
          text_as_customer_6: "<b>We use LemonSqueezy as payment processor.</b> When you make a purchase, a LemonSqueezy customer will be created with your email. This is done to process the payments. If you make a purchase and delete the SaaSConstruct account, LemonSqueezy will still keep information about payments that were made: please refer to https://www.lemonsqueezy.com/privacy",
          text_as_customer_7: "<b>After the payment, we will send a Github invitation to our repository to your email inbox. The email will be submitted to the Github API payload in order to create an invitation.</b> Please refer to Github privacy policy: https://docs.github.com/en/site-policy/privacy-policies. </b>",
          text_as_customer_8: "<b>After the payment, we also might (depending on the purchase) send you a Discord invitation to your email inbox. We do not disclose your email to Discord when creating such an invitation.</b>",
          title_retention: "Retention of data",
          text_retention_1: "We will retain your information as long as your account is active, as necessary to provide you with the services or as otherwise set forth in this policy.",
          text_retention_2: "We will also retain and use this information as necessary for the purposes set out in this policy and to the extent necessary to comply with our legal obligations, resolve disputes, enforce our agreements and protect our legal rights.",
          text_retention_3: "You can choose to delete your account at any time.",
        },
      ],
      date_text: "Last updated: August 16, 2024."
    }
  }
}
</script>
