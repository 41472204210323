<template>
  <div class="doc-container font-roboto-light top-border">
    <div class="container">
      <div class="row flex-xl-nowrap g-0">
        <SidebarDocumentation/>
        <!-- ****************************** DOC MAIN BODY ********************************* -->
        <main class="col-xl-9 col-md-8 doc-main-body">

          <h2>Add a chatbot trained on your data (AWS Bedrock and Pinecone)</h2>

          <h3>What is AWS Bedrock and Pinecone?</h3>
          <p><span class="font-roboto-medium">AWS Bedrock</span> is an AWS service that gives you access to a variety of LLMs (large language models) that can generate text data. If you send a request to LLM asking for help with general tasks (such as writing an email or answering common questions), they will usually be able to help.<br>
            But these models don't have any knowledge about your custom data (e.g. about your SaaS, what it can do, etc.). So we need to store the data and then use it when we make a request to LLMs. Your custom data is stored in a vector database.
          </p>

          <p>
            <span class="font-roboto-medium">Pinecone</span> is a vector database where you can store your custom data (information about your SaaS), so when the user asks your chatbot a question, the backend of your SaaS will find relevant parts of that data that answer the question, and send that data to LLM to construct a final result.
          </p>

          <p>If this sounds confusing, do not worry, SaaSConstruct does all this behind the scenes, you only need to do an initial configuration.</p>


          <h3>1. Get access to AWS Bedrock models (dev and prod AWS accounts)</h3>
          <ul class="list-item-one">
            <li>In your dev and prod account, go to the AWS Bedrock service, go to model access, and request 2 models: one with text modality and one with embedding modality.
              <InfoContainer class="mt-15">
                <span class="font-roboto-medium">INFO</span>
                To start with, and to test things out, we suggest you choose cheap and smaller models (you can always change this later), such as the Titan G1 (both Text and Embedding).
              </InfoContainer>
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/ai/ai_models1.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/ai/ai_models2.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
            </li>
          </ul>


          <h3 style="margin-top: 40px !important;">2. Create Pinecone API keys and store them in AWS Manager (dev and prod AWS accounts)</h3>
          <ul class="list-item-one">
            <li>
              Create an account in <a href="https://www.pinecone.io/" target="_blank" rel="noopener noreferrer" class="underline-link">Pinecone</a>.
            </li>
            <li>
              Create two API keys: one for the AWS dev account, one for the AWS prod account.
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/ai/pinecone_api_key.png"
                    alt=""
                    class="shapes screen-one"
                    style="width: 25%; height: 25%"
                />
              </div>
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/ai/pinecone_api_key_2.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
            </li>
            <li class="mt-25">
              Update secrets in AWS secret manager (both dev and prod AWS accounts). <br>
              For the dev account, add <span class="font-roboto-medium">pinecone_api_key</span> (your api key for the dev account), and <span class="font-roboto-medium">pinecone_index_name</span> (name it <span class="font-roboto-medium">saasfaq-dev</span>)<br>
              For the prod account, add <span class="font-roboto-medium">pinecone_api_key</span> (your api key for the prod account), and <span class="font-roboto-medium">pinecone_index_name</span> (name it <span class="font-roboto-medium">saasfaq-prod</span>)<br>
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/ai/ai_secret_manager.png"
                    alt=""
                    class="shapes screen-one"
                />
              </div>
            </li>
          </ul>

          <h3 style="margin-top: 40px !important;">3. Run the script to store your custom data to Pinecone</h3>
            <ul class="list-item-one">
              <li>
                Open <span class="font-roboto-medium">faq.md</span> in <span class="font-roboto-medium">tools/pinecone</span> and rewrite it with your SaaS/product information.
                <InfoContainer class="mt-15">
                  <span class="font-roboto-medium">INFO</span>
                  If you are just testing things out for now, you can leave faq.md as it is and rewrite it later. Just run the script again.
                </InfoContainer>
              </li>
              <li class="mt-15">
                Activate the Python environment and install the libraries.
              </li>
              <CodeContainer :code="activateEnvCode.code" :language="activateEnvCode.language"/>
              <li class="mt-15">Create temporary AWS profiles (dev and prod)
                <ul class="list-item-one">
                  <li>
                    Go to your Access Portal and copy the commands to set the AWS credentials to your local machine. Choose <span class="font-roboto-medium">Option 2</span>. These are temporary credentials that will only be used for the initial setup.<br>
                    When copying the credentials to a file, name it dev.

                    <div class="documentation-image">
                      <img
                          src="../../../../assets/images/documentation/ai/ai_creds.png"
                          alt=""
                          class="shapes screen-one"
                      />
                    </div>
                    <div class="documentation-image">
                      <img
                          src="../../../../assets/images/documentation/ai/ai_creds2.png"
                          alt=""
                          class="shapes screen-one"
                      />
                    </div>
                    <div class="documentation-image">
                      <img
                          src="../../../../assets/images/documentation/ai/ai_creds3.png"
                          alt=""
                          class="shapes screen-one"
                          style="width: 75%; height: 75%"
                      />
                    </div>
                  </li>
                </ul>
              </li>
              <li class="mt-25">
                Run the script (for both dev and prod).
                <InfoContainer class="mt-15">
                  <span class="font-roboto-medium">INFO</span>
                  - YOUR_ENV is either dev or prod. <br>
                  - If you have gained access to different models in the previous steps and want to use them, please change model_text and model_embed accordingly.
                </InfoContainer>
              </li>

              <CodeContainer :code="scriptInit.code" :language="scriptInit.language"/>

              <li class="mt-15">Now you can see the database indexes in Pinecone:
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/ai/ai_index.png"
                    alt=""
                    class="shapes screen-one"
                    style="width: 100%; height: 100%"
                />
              </div>
              </li>
              <li class="mt-15">
                Test the setup with the test script (for both dev and prod).
              </li>
              <CodeContainer :code="scriptTest.code" :language="scriptTest.language"/>
              <li class="mt-15">Now your website has a chatbot! Congratulations
              <div class="documentation-image">
                <img
                    src="../../../../assets/images/documentation/ai/chatbot_gif.gif"
                    alt=""
                    class="shapes screen-one"
                    style="width: 100%; height: 100%"
                />
              </div>
                <InfoContainer class="mt-25">
                  <span class="font-roboto-medium">INFO</span>
                  If you used different models than in this documentation, please change model ids in api_config.py file in the /backend/api/entrypoint folder.
                </InfoContainer>
              </li>


            </ul>

<!--    api_config     -->


          <DocumentationPagination/>
        </main> <!-- /.doc-main-body -->
      </div>
    </div>
  </div>
</template>

<script>
import SidebarDocumentation from "@/components/service-documentation/commons/SidebarDocumentation.vue";
import DocumentationPagination from "@/components/service-documentation/commons/DocumentationPagination.vue";
import {ref} from "vue";
import InfoContainer from "@/components/service-documentation/commons/InfoContainer.vue";
import CodeContainer from "@/components/service-documentation/commons/CodeContainer.vue";

export default {
  name: 'AddChatbot',
  components: {
    CodeContainer,
    InfoContainer,
    SidebarDocumentation,
    DocumentationPagination,
  },
  setup() {

    const activateEnvCode = ref({
      language: 'bash',
      code:
          '# from the root folder of the project (folder /saasconstruct)\n' +
          'cd cdk # navigating to the infrastructure folder\n' +
          '# activating the virtual environment\n' +
          'cdk\\.venv\\Scripts\\activate # for Windows\n' +
          'source cdk/.venv/bin/activate # for Posix (MacOS, Linux)\n' +
          'pip install -r tools/pinecone/requirements.txt # installing cdk libraries'
    });

    const exportAwsKey = ref({
      language: 'bash',
      code:
          "export AWS_ACCESS_KEY_ID=\"ASIA27P6XSBHH7MXL\"\n" +
          "export AWS_SECRET_ACCESS_KEY=\"P3I1KcCO/ENtQX8Lomx7XhkKgPPVZHK96CSWi\"\n" +
          "export AWS_SESSION_TOKEN=\"IQoJb3JpZ2luX2VjEOX//////////wEaDGV1LWNlbnRyYWwtMSJIMEYCIQCD5CRONW6crS4Jp3hMf66cLf1ImrjK4j67i2TekQgPnAIhANbH6uqkpIpeH1pXOiBm1c7+zOQvTdnVkEKY2THh34adKu8CCL///////////wEQARoMNzU0ODM3ODUyMjM5Igxp+ViwKEB+3rTdvTYqwwKrrpvsD9ZrxfGh46ysHydDggT7iDPRhTp5qx9X8i17CEe7VhrN3gYJmcS6SUk6DcNJR+kqra+D5czQn7IjnNvV5aj9VY0lQs/Yz+kJcQlurZgIjUM1Jit72Ur0uk7VKyqPQ37mUXqmsCRTPeULc8fnB/Jn+x2SM1tRoAE3NhpBqWXq5fHYsYk196Xma1QS0anyuYFzccJtbfgrWsSqEcmv3POA5zw7yrSffcIj2jRuwdYeGw+rUYIcS3xWPCTfz8Rz1Jj+fQlzL3kIRQ/oLQUElySUoTCT8hKxDIRDV+WgqMaG0ikbKldj4lS3Oihx8+pepZEUkbjm05gMgr3jOspatZmLr1o5j1MfbWBEJ0SaURl2+4qUwv4vcbldJBkMWIVxZtJQs8SbeK1GJF73eFQ1LbvDpa4OQXwn6in70R2pu56ZGTDKo4m1BjqmAavkxgL/h7BWXFb60i0p4RvBqUhH9yV/cdea0YLaP9uRQGQb6QRSmaCF7+s/vAN7+PLo4B7D1G1pevrU+JlIcpGOZNlj5Fyx/wcfU1DZHM4ZkJwX23UqmF+0TB4Z8NRcRyZK+PTu4Ht4URDZfM5fyfOyrQ37iiAFnV+nIjEbvYmp8QSKNTvGzG7rmcgfk=\""
    });
    const checkAccount = ref({
      language: 'bash',
      code:
          '# compare the output of this command with your account id\n' +
          'aws sts get-caller-identity --query Account --output text'
    });

    const scriptInit = ref({
      language: 'bash',
      code:
          '# from the root folder of the project (folder /saasconstruct)\n' +
          '# replace 3.11 with your version of Python if needed\n' +
          'python3.11 tools/pinecone/init_pinecone.py \\\n' +
          '    --pinecone_api_key YOUR_PINECONE_API_KEY \\\n' +
          '    --aws_profile YOUR_ENV \\\n' +
          '    --aws_region YOUR_REGION \\\n' +
          '    --environment YOUR_ENV \\\n' +
          '    --model_text amazon.titan-text-express-v1 \\\n' +
          '    --model_embed amazon.titan-embed-text-v1'
    });
    const scriptTest = ref({
      language: 'bash',
      code:
          '# from the root folder of the project (folder /saasconstruct)\n' +
          '# replace 3.11 with your version of Python if needed\n' +
          'python3.11 tools/pinecone/test_pinecone.py \\\n' +
          '    --pinecone_api_key YOUR_PINECONE_KEY \\\n' +
          '    --aws_profile YOUR_ENV \\\n' +
          '    --aws_region YOUR_REGION \\\n' +
          '    --environment YOUR_ENV \\\n' +
          '    --model_text amazon.titan-text-express-v1 \\\n' +
          '    --model_embed amazon.titan-embed-text-v1 \\\n' +
          '    --question "List the features"'
    });

    return {
      activateEnvCode,
      exportAwsKey,
      checkAccount,
      scriptInit,
      scriptTest
    };
  }
}
</script>
