<template>
  <div class="blog">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <h2>{{ title }}</h2>
          <div>
            <img src="@/assets/images/assets/ai_app_dark_with_bedrock2.svg" alt="" />
          </div>
          <div v-html="sanitizedContent"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DOMPurify from 'dompurify';

export default {
  name: 'BlogPostSimpleTechStackForAwsCloudMainArea',
  data() {
    return {
      title: 'The Tech Stack of a Simple SaaS for AWS Cloud',
      content: `
        <h3>Introduction</h3>
        <p>------------------------</p>
        <p>Note 1: Here is the hosted interactive demo: <a class="plausible-event-name=DemoClick" href="https://demo.saasconstruct.com" target="_blank">demo.saasconstruct.com</a></p>
        <p>Note 2: My monthly bill for each SaaS setup is 3-5$ per month, and it's mostly CI/CD costs.</p>
        <p>Note 3: Template is here: <a href="https://saasconstruct.com/#pricing">saasconstruct.com</a>.</p>
        <p>------------------------</p>
        <p>I've done several AI PoCs and MVPs on AWS, <b>and it's always similar things</b>:
        <ul>
        <li> host the frontend somewhere</li>
        <li> make a call to the backend</li>
        <li> the backend gets/updates data from binary storage/database</li>
        <li> the backend does some AI logic or calls another service and sends back the result</li>
        <li> there are two isolated AWS accounts: dev and prod</li>
        <li> CI/CD for deployments</li>
        <li> infrastructure-as-code for cloud resources declaration  </li>
        </ul>
       </p>

        <p>So, I thought I'd build a simple solution to bootstrap such things on AWS. And write a blog post about it.</p>

        <p>I decided to add some features, like Stripe payments (and LemonSqueezy payments if you don't want to worry about sales tax/VAT) and payment management, authentication, traffic alarms, and others. I also thought it needed to be configurable, like replacing API Gateway and AWS Lambda with ELB and ECS for longer tasks.</p>

        <h3>Frontend</h3>
        <p>I picked the commonly declared easiest framework to start with. It is <b>Vue</b>, and, as far as I understood, the second most popular framework out there. I picked it because not only is it the easiest, but also I had some experience with it.</p>

        <p>The website is a standard SPA application with Vite as a build tool. For styling, I use Bootstrap because it is, too, very easy to work with, and also because it does not cause a lot of pain when migrating from one version of the frontend framework to another.</p>

        <h3>Frontend Hosting</h3>
          <p>There are two options:</p>
          <ul>
            <li>S3 and CloudFront (CDN)</li>
            <li>AWS Amplify Hosting, which is a wrapper around S3 and CloudFront, easy to work with but less configurable. E.g., you can't do anything with CloudFront distribution, as it is not visible. You also can't geo-block your application except doing it with redirects.</li>
          </ul>
          <p>I went with <b>Amplify Hosting</b> as its primary focus of AWS in frontend hosting solutions and because it is easy to set up, attach a domain, etc. </p>
          <p>Since it is a Pay-as-you-go basis, I have set up a <b>traffic alarm</b>: if there is more than a certain number of hits per 10 seconds, I get a notification.</p>

          <h3>Backend</h3>
          <p>The backend is the <b>API Gateway</b>, which does the rate limiting, and <b>AWS Lambda (Python)</b>, which does the business and general logic:</p>
          <ul>
            <li>Checking if the user is authenticated</li>
            <li>Processing payments and manage subscriptions (customer portal)</li>
            <li>Sending emails</li>
            <li>etc.</li>
          </ul>

            <p>I also have another AWS Lambda function that creates a user in the database after signing up in Cognito.</p>

            <p>There is shared utilities where I put some shared functionality, for example, emailing. Also, logging functionality, for example, an email is sent to me if there was a payment error.</p>

            <h3>Authentication</h3>
            <p>Authentication is pain, I know, and I did not want to use a third-party service. So I stayed with <b>AWS Cognito</b>. It is pretty cheap.</p>

            <p>You can say, just use <b>AWS Amplify Auth</b> (which is a wrapper around AWS Cognito), but I had some problems with it. I even wrote a post on Reddit:</p>
            <p><a href="https://www.reddit.com/r/aws/comments/172g2ic/my_list_of_problems_with_cognito_and_amplify_for">My list of problems with Amplify for authentication</a></p>
            <p>And there us some other post with an even bigger list from some frustrated user (it is an old post though). </p>
            <p><a href="https://www.reddit.com/r/aws/comments/m77p5g/aws_cognito_amplify_auth_bad_bugged_baffling/">here</a></p>

            <p>Besides, if you use Amplify only, you are stuck with the whole ecosystem with no chance to change something. E.g., if you want to have access to the CloudFront distribution (e.g., when you want to geoblock certain regions), tough luck, you can't see it with Amplify Hosting. I also had other issues: one of the examples being CDK creation from Amplify resources, which was a pain point for me.</p>

            <p>So what I did is a hybrid approach (which is somewhat popular according to Reddit): the <b>AWS Amplify JS library </b> allows you to import cloud resources you create yourself, like user pools, so I created them with CDK and then just used the Amplify JS library for authentication.</p>

            <p>In this case, I can always change whatever I want, swap cloud resources (for example, I could go from Amplify Hosting to CloudFront + S3 if I need access to CloudFront distribution.</p>

            <h3>Emails</h3>
            <p><b>AWS SES</b>. It is the main AWS Email service. It sends everything, including Cognito authentication emails, request from the contact form, etc. The only thing you need to understand is that in your dev AWS account, you will need to create verified identities first to be able to send (I automated it via IaC), and in the production AWS account, you will need to request production access (which is just a couple of clicks).</p>
            <p>Using AWS SES, email notifications are sent in the following scenarios:</p>
              <ul>
                <li>When payment errors occur.</li>
                <li>In case of spikes in web traffic.</li>
                <li>If the CI/CD rollout fails.</li>
                <li>For other situations, such as authentication emails and inquiries from the contact form, etc.</li>
              </ul>
            <h3>Storage</h3>
            <p><b>DynamoDB</b> as a database. Easy, fast, and managed. Yes, I had to think about access patterns, but generally, it is good to work with and also does not cost me anything while I validate/build. Since I plan to work on several products and want to keep them isolated, I can't put RDS/DocumentDB in dev and prod accounts per project (it costs way too much).</p>

            <h3>Payments</h3>

    <p>I added two payment systems, and it is possible to choose which one to use because they work similarly:</br>
    - <b>Stripe</b> is popular and easy to integrate, plain and simple. When a user buys a product, I use Stripe checkout, and for managing subscriptions, I use the Stripe Customer portal.</p>
    <p> - <b>LemonSqueezy</b> is very similar to Stripe, but it is also a Merchant of Record, meaning that it handles sales tax/VAT tax for you. It also has a checkout for buying a subscription and a Customer portal for managing them.</p>
        <p>There are endpoints I wrote for the Stripe/LemonSqueezy webhooks, which handle all the logic.</p>

        <h3>Infrastructure as Code</h3>
        <p>So there are a lot of things to choose from:</p>
        <ul>
          <li>Something like Terraform or OpenTofu (fully open-source alternative which is based on Terraform)</li>
          <li>Pulumi</li>
          <li>CDK</li>
          <li>CloudFormation</li>
        </ul>

        <p>I chose <b>AWS CDK</b>, and here are my reasons:</p>
        <ul>
          <li>It is easy to work with</li>
          <li>It is popular and mature enough</li>
          <li>It is way better than AWS CloudFormation, in my opinion</li>
          <li>It is an AWS library, and I use AWS</li>
          <li>I can write it in Python, TypeScript, or other languages. Since I use Python on the backend and TypeScript on the frontend, it is a good choice.</li>
        </ul>

        <p>The reason I did not choose Terraform is that CDK is easier; it allows creating resources in a simple manner, at least in my opinion. I like OOP and try to construct my cloud infrastructure accordingly. A big benefit is that CI/CD is included (CDK pipelines), so I don't have to invent that.</p>

        <h3>CI/CD</h3>
        <p>I chose <b>CDK pipelines</b> because it is, again, easy. Just connect the pipeline to the GitHub repository, and you are good to go. Git push to the development branch -> it will be rolled out to the development account. Git push to the main (or pull request) -> production rollout.</p>
        <h3>Alarms and Rate Limits</h3>
        <p>I've set up Rate Limiting to prevent getting spammed through the API gateway. I've set up two <b>CloudWatch alarms</b>:</p>
        <ul>
          <li>To alert me when the hosted website is getting spammed with requests.</li>
          <li>To alert me when the API Gateway is getting spammed with requests.</li>
        </ul>

        <p>I've also set up <b>billing alarms</b> to inform me if I am about to spend too much.</p>

        <h3>Logging</h3>
        <p><b>CloudWatch</b> logs the events, you can see them both in the AWS Console and directly in the IDE via extensions.</p>


        <h3>AI</h3>
        <p>The choice was between using either OpenAI (with GPT models) or AWS Bedrock (with Claude models). This decision was challenging because, while AWS Bedrock with Claude integrates easily with AWS, OpenAI is more commonly used. Both companies offer top-tier AI models. For now, I have chosen to stick with <b>AWS Bedrock</b>. This might change in the future, but for now, I appreciate the simplicity. For the vector database, I use Pinecone, which has serverless indexes.</p>
        <p>An example of the AI application I built here is a <b>RAG system</b>, which is essentially a chatbot that can answer questions based on your data. You store information in a vector database, and on the query you do a similarity search, and then just use LLM to give an answer based on the result of that search. I currently use simple models to avoid costs, but to switch to different models is as simple as changing a line of code.</p>

        <h3>Programming Languages</h3>
        <p>I was initially a Java developer, but then became a Python developer because I developed machine learning and deep learning services. The most libraries in that space are developed in Python or featuring a Python wrapper. Besides, Python integrates seamlessly with AWS, whether in AWS Lambda (e.g., using the AWS Lambda Powertools library) or in CDK. So in the end, both the backend and cloud infrastructure (via CDK) are implemented in Python.</p>

        <p>My secondary language is TypeScript, because of the frontend frameworks. Although I previously used JavaScript, I found its lack of types to be confusing as the codebase expanded.</p>

        <h3>AWS bills</h3>
        <p>
  Because I don't experience tons of traffic, I pay very little (3-5$ per month, and it's mostly CI/CD costs).
I have a CDN (comes with Amplify Hosting) and a small caching layer (in AWS Lambda). Also some things are covered by AWS monthly free tier as well.
Yes, if the product gets very large user base, I might need to optimise some resources (switch to provisioned DynamoDB and using DAX).
But for now it works.
        </p>

        <h3>Conclusion</h3>
        <p>This setup currently serves my needs.</p>

        <p>I have included this tech stack as a boilerplate (which I am actively developing and updating) in my AWS template on  <a href="https://saasconstruct.com/#pricing">saasconstruct.com</a>.</p>

        <p>I will be exploring what other features can be added...</p>
      `,
    };
  },
  computed: {
    sanitizedContent() {
      const config = {
        ADD_ATTR: ['target'],
      };

      return DOMPurify.sanitize(this.content, config);
    }
  }

}
</script>
