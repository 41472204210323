<template>
  <div>
    <div class="hero-banner-five">
      <div class="container">
        <div class="row">
          <div class="col-xl-10 col-lg-11 col-md-10 m-auto">
            <!--            <h1 class="hero-heading">{{ $t('intro.heading') }} <span>{{ $t('intro.highlight') }}</span></h1>-->
            <h1 class="hero-heading">
              &nbsp;{{ animatedText }}<span class="highlight">{{ highlightedText }}</span>
            </h1>
            <p class="hero-sub-heading">
              {{ $t('intro.subHeading') }}
            </p>
            <a
                class="highlight-link plausible-event-name=DemoClick"
                href="https://demo.saasconstruct.com"
                target="_blank"
                rel="noopener noreferrer"
            >
              <span>{{ $t('intro.highlight-link') }}</span>
            </a>
          </div>
          <div class="block-style-sixteen-new">
            <img
                src="../../assets/images/assets/ai_app_dark_with_bedrock.svg"
                alt=""
                class="shapes screen-one"
                style="padding-left: 40px !important;"
            />
            <div class="mt-100 container">
              <div class="d-flex flex-row justify-content-around">
                <div class="intro-get-started-button">
                  <ul class="d-flex align-items-center justify-content-center">
                    <li>
                      <RequestButton
                          :is-making-request="creatingSession"
                          custom-class="request-button-default plausible-event-name=BuyClick"
                          :button-label="$t('intro.getStarted')"
                          @click="createCheckoutSession"
                      />
                    </li>
                  </ul>
                </div>
                <div class="intro-get-started-button">
                  <ul class="d-flex align-items-center justify-content-center">
                    <li>
                      <LinkToExternal
                          href="https://demo.saasconstruct.com"
                          custom-class="link-to-default plausible-event-name=DemoClick"
                          :link-label="$t('intro.seeDemo')"/>
                    </li>
                  </ul>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div> <!-- /.partner-slider-two -->
  </div>
</template>


<script>

import {useAuthStore} from "@/store/auth";
import {isLemonSqueezy, isStripe} from "@/composables/useEnvironment";
import usePricingProducts from "@/composables/usePricingProducts";
import useCreateCheckoutSessionForOneTimePaymentsStripe
  from "@/composables/useCreateCheckoutSessionForOneTimePaymentsStripe";
import {useI18n} from "vue-i18n";
import RequestButton from "@/components/buttons/RequestButton.vue";
import LinkToExternal from "@/components/buttons/LinkToExternal.vue";
import {onMounted, onUnmounted, ref} from "vue";
import useCreateCheckoutSessionForOneTimePaymentsLemonSqueezy
  from "@/composables/useCreateCheckoutSessionForOneTimePaymentsLemonSqueezy";


export default {
  name: 'SaasInfraIntro',
  components: {
    RequestButton,
    LinkToExternal
  },
  setup() {
    const creatingSession = ref(false)
    const {t} = useI18n();
    const animatedText = ref('');
    const highlightedText = ref('');
    let currentIndex = 0;
    const texts = [
      {regular: t('intro.firstLineText'), highlight: t('intro.firstHighlight')},
      {regular: t('intro.secondLineText'), highlight: t('intro.secondHighlight')},
      {regular: t('intro.thirdLineText'), highlight: t('intro.thirdHighlight')},
      {regular: t('intro.fourthLineText'), highlight: t('intro.thirdHighlight')}
    ];
    let timeoutId = null;

    function typeText(texts, index = 0) {
      if (index < texts.regular.length) {
        animatedText.value += texts.regular.charAt(index);
        timeoutId = setTimeout(() => typeText(texts, index + 1), 50);
      } else if (index < texts.regular.length + texts.highlight.length) {
        highlightedText.value += texts.highlight.charAt(index - texts.regular.length);
        timeoutId = setTimeout(() => typeText(texts, index + 1), 50);
      } else {
        timeoutId = setTimeout(() => deleteText(), 3000); // Wait before starting to delete
      }
    }

    function deleteText() {
      if (highlightedText.value.length > 0) {
        highlightedText.value = highlightedText.value.substring(0, highlightedText.value.length - 1);
        timeoutId = setTimeout(deleteText, 25);
      } else if (animatedText.value.length > 0) {
        animatedText.value = animatedText.value.substring(0, animatedText.value.length - 1);
        timeoutId = setTimeout(deleteText, 25);
      } else {
        currentIndex = (currentIndex + 1) % texts.length;
        texts[0].regular = t('intro.firstLineText');
        texts[0].highlight = t('intro.firstHighlight');
        texts[1].regular = t('intro.secondLineText');
        texts[1].highlight = t('intro.secondHighlight');
        texts[2].regular = t('intro.thirdLineText');
        texts[2].highlight = t('intro.thirdHighlight');
        texts[3].regular = t('intro.fourthLineText');
        texts[3].highlight = t('intro.fourthHighlight');
        timeoutId = setTimeout(() => typeText(texts[currentIndex]), 200); // Wait before starting next text
      }
    }

    onMounted(() => {
      typeText(texts[currentIndex]); // Start the typing effect when component mounts
    });

    onUnmounted(() => {
      clearTimeout(timeoutId); // Clean up the timeout when component is unmounted to prevent memory leaks
    });

    const {getMostPopularProduct} = usePricingProducts();
    const store = useAuthStore()

    let createCheckoutSessionForOneTimePayment;
    let createCheckoutSessionForOneTimePaymentWithoutLogin;

    if (isStripe()) {
      const {
        createCheckoutSessionForOneTimePaymentStripe,
        createCheckoutSessionForOneTimePaymentWithoutLoginStripe,
      } = useCreateCheckoutSessionForOneTimePaymentsStripe();

      createCheckoutSessionForOneTimePayment = createCheckoutSessionForOneTimePaymentStripe;
      createCheckoutSessionForOneTimePaymentWithoutLogin = createCheckoutSessionForOneTimePaymentWithoutLoginStripe;
    }

    if (isLemonSqueezy()) {
      const {
        createCheckoutSessionForOneTimePaymentLemonSqueezy,
        createCheckoutSessionForOneTimePaymentWithoutLoginLemonSqueezy,
      } = useCreateCheckoutSessionForOneTimePaymentsLemonSqueezy();

      createCheckoutSessionForOneTimePayment = createCheckoutSessionForOneTimePaymentLemonSqueezy;
      createCheckoutSessionForOneTimePaymentWithoutLogin = createCheckoutSessionForOneTimePaymentWithoutLoginLemonSqueezy;
    }

    const createCheckoutSession = async () => {
      const item = getMostPopularProduct()
      if (store.isAuthenticated) {
        await createCheckoutSessionForOneTimePayment(item, creatingSession);
      } else {
        await createCheckoutSessionForOneTimePaymentWithoutLogin(item, creatingSession);
      }
    }

    return {
      store,
      createCheckoutSession,
      creatingSession,
      animatedText,
      highlightedText
    };
  },
}
</script>

<style scoped>

.hero-banner-five .highlight-link {
  text-decoration: underline;
  text-decoration-color: #7736DC;
  text-decoration-thickness: 5px;
  text-decoration-style: solid;
  font-family: "Roboto", sans-serif !important;
  font-size: 20px;
  padding: 20px 0 15px;
  color: #000;
}

.hero-heading {
  transition: opacity 0.5s;
  opacity: 1;
}

.hero-heading.hide {
  opacity: 0;
}

</style>
